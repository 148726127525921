import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card, Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import airplaneimg1 from "../assets/img/airplaneimg1.jpg";
import { S3_BASE_URL } from "../helper/config";
import { getApi, postApi, postApiformdata } from "./axiosInstance/axiosConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Sentry } from "react-activity";
export default function Editairplane() {
  const { id } = useParams();
  const [aircraft, setAircraft] = useState({
    airplaneType: "",
    modelNumber: "",
    capacity: "",
    aircraftPic: "",
    eastToWestSpeed: null,
    westToEastSpeed: null,
    engineCostPerHour: null,
    fuelCostPerHour: null,
    maintenanceCostPerHour: null,
    totalCostPerHour: null,
    profileImagePreview: [airplaneimg1],
  });
  const [Seats, setSeats] = useState("14");
  const [Type, setType] = useState("Ultra Long Range Jet");
  const [EastWest, setEastWest] = useState("");
  const [WestEast, setWestEast] = useState("");
  const [EngineCost, setEngineCost] = useState("");
  const [FuelCost, setFuelCost] = useState("");
  const [MaintenanceCost, setMaintenanceCost] = useState("");
  const [TotalCost, setTotalCost] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prvImgs, setprvImg] = useState([]);
  const [sendingImg, setsendingImg] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [primaryImg, setPrimaryImg] = useState(null);
  const [validated, setValidated] = useState(false);
  // console.log(prvImgs, "prvImgs==>");
  const handleClose = () => {
    setSelectedImg(null);
    setShow(false);
  };
  const handleShow = (imgUrl) => {
    // console.log(imgUrl);
    setSelectedImg(imgUrl);
    setShow(true);
  };
  // useEffect(() => {
  //   console.log(prvImgs, "prvImgs>> arr check");
  // }, [prvImgs]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const total = aircraft.engineCostPerHour + aircraft.fuelCostPerHour + aircraft.maintenanceCostPerHour;
    // setTotalCostPerHour(total);
    setAircraft((prevData) => ({
      ...prevData,
      [name]: value,
      //   totalCostPerHour:total
    }));
    // console.log("change", aircraft);
  };
  useEffect(() => {
    let total =
      parseInt(aircraft.engineCostPerHour) +
      parseInt(aircraft.fuelCostPerHour) +
      parseInt(aircraft.maintenanceCostPerHour);
    setAircraft((prevData) => ({
      ...prevData,

      totalCostPerHour: total,
    }));
  }, [
    aircraft.fuelCostPerHour,
    aircraft.maintenanceCostPerHour,
    aircraft.engineCostPerHour,
  ]);
  // const getTotal = () => {
  //   let total =
  //     parseInt(aircraft.engineCostPerHour) +
  //     parseInt(aircraft.fuelCostPerHour) +
  //     parseInt(aircraft.maintenanceCostPerHour);
  //   console.log("total", total);
  //   setAircraft((prevData) => ({
  //     ...prevData,
  //     totalCostPerHour: total,
  //     //   totalCostPerHour:total
  //   }));
  //   return total;
  // };
  const handleImageChange = (e) => {
    const file = e.target.files;
    // console.log("files--------", file);
    if (file) {
      let prvImg = [...prvImgs];
      let sendingImg = [];
      for (let x of file) {
        // console.log(x, "checking x");
        const imagePreviewUrl = URL.createObjectURL(x);
        prvImg.push(imagePreviewUrl);
        sendingImg.push(x);
      }
      setprvImg(prvImg);
      setsendingImg(sendingImg);
      //   setAircraft((prevData) => ({
      //     ...prevData,
      //     aircraftPic: sendingImg,
      //     // profileImagePreview: imagePreviewUrl,
      //   }));
      //   const imagePreviewUrl = URL.createObjectURL(file);
      //   setAircraft((prevData) => ({
      //     ...prevData,
      //     aircraftPic: file,
      //     profileImagePreview: imagePreviewUrl,
      //   }));
    }
  };
  let frontImg = async (e, index) => {
    e.preventDefault();
    const imagefrontImg = prvImgs[index];
    // console.log("imgtodelete",imageToDelete);
    const containsBlob = imagefrontImg.includes("blob:");
    if (!containsBlob) {
      const imageTofront = imagefrontImg.split("/").pop();
      let obj = {
        aircraftId: id,
        aircraftPic: imageTofront,
      };
      const response = await postApi("frontImgofAircraft", obj);
      if (response?.status) {
        const data = await response.data;
        // console.log("frontImgofAircraftresponse",data);
        setPrimaryImg(`${S3_BASE_URL}${data.frontImg}`);
        toast.success("Image set as primary.", {
          autoClose: 1000, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } else {
      setSubmitting(false);
      toast.error("Please save image to make it primary");
    }
  };
  const removeImg = async (e, index) => {
    e.preventDefault();
    const imageUrlToDelete = prvImgs[index];
    // console.log("imgtodelete",imageToDelete);
    const containsBlob = imageUrlToDelete.includes("blob:");
    if (!containsBlob) {
      const imageToDelete = imageUrlToDelete.split("/").pop();
      let obj = {
        aircraftId: id,
        aircraftPic: imageToDelete,
      };
      const response = await postApi("deletepic", obj);
      if (response?.status) {
        const data = await response.data;
        toast.success("pic deleted Successfully", {
          autoClose: 1000, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    }
    let arr = [...prvImgs];
    arr.splice(index, 1);
    setprvImg(arr);
  };

  const handlePrimaryAction = async (index) => {
    const imageUrlToSetPrimary = index;
    // console.log(imageUrlToSetPrimary);
    let obj = {
      primary: imageUrlToSetPrimary,
    };
    const response = await postApi("setPrimaryPic", obj);
  };

  let getAircraft = async () => {
    try {
      let res = await getApi("getAircraft?aircraftId=" + id);
      console.log("aircraft", res);
      setAircraft({
        ...res.data,
        profileImagePreview: res.data.aircraftPic.length
          ? res.data.aircraftPic.map((pic) => `${S3_BASE_URL}${pic}`)
          : [airplaneimg1],
      });
      setLoading(false);
      setPrimaryImg(`${S3_BASE_URL}${res.data.frontImg}`);
      let arr = [];
      res?.data?.aircraftPic?.map((pic) => {
        let url = `${S3_BASE_URL}${pic}`;
        arr.push(url);
      });
      setprvImg(arr);
    } catch (error) {
      console.log(error, "errorr here");
      setError(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("workinguseEffect");
    getAircraft();
  }, [id, submitting, primaryImg]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      const formData = new FormData();
      formData.append("airplaneType", aircraft.airplaneType);
      formData.append("capacity", aircraft.capacity);
      formData.append("modelNumber", aircraft.modelNumber);
      formData.append("aircraftPic", aircraft.aircraftPic);
      formData.append("eastToWestSpeed", aircraft.eastToWestSpeed);
      formData.append("westToEastSpeed", aircraft.westToEastSpeed);
      formData.append("engineCostPerHour", aircraft.engineCostPerHour);
      formData.append("fuelCostPerHour", aircraft.fuelCostPerHour);
      formData.append(
        "maintenanceCostPerHour",
        aircraft.maintenanceCostPerHour
      );
      formData.append("totalCostPerHour", aircraft.totalCostPerHour);
      formData.append("aircraftId", aircraft._id);
      sendingImg?.forEach((val) => {
        formData.append("aircraftPic", val);
      });
      try {
        setSubmitting(true);
        const response = await postApiformdata("editAirplane", formData);
        if (response?.status) {
          const data = await response.data;
          toast.success("Aircraft edit Successful", {
            autoClose: 1000, // Display toast for 3 seconds
            onClose: () => {
              setSubmitting(false);
            },
          });
          await getAircraft();
        } else {
          setSubmitting(false);
          toast.error(response);
        }
      } catch (err) {
        console.error(err);
        setMessage("Failed to update profile");
      }
    }
  };
  return (
    <>
    {console.log("loading",loading)
    }
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Edit Airplane</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Airplane"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={12}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="airplaneType"
                        placeholder="Enter Type"
                        value={aircraft?.airplaneType}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide type.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Aircraft Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="modelNumber"
                        placeholder="Enter Type"
                        value={aircraft?.modelNumber}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Seats</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="capacity"
                        placeholder="Enter Seats"
                        value={aircraft?.capacity}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft Seats.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Images</Form.Label>
                      <Form.Control
                        id="categoryimg"
                        name="categoryimg"
                        className="displaynone"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                      />
                      <Form.Label className="fileupload" htmlFor="categoryimg">
                        <Icon
                          className="uplaodicon"
                          icon="ion:camera-outline"
                        ></Icon>
                        <h3>Please select image</h3>
                      </Form.Label>
                      {/* <div className="formimg">
                        {console.log("aircraft", aircraft)}
                        {aircraft?.profileImagePreview?.map((imgUrl, index) => (
                          <div key={index} className="image-container">
                            <img key={index} src={imgUrl} alt="Aircraft img" />
                            <Icon
                              className="closebtn"
                              icon="material-symbols:close"
                            ></Icon>
                          </div>
                        ))}
                      </div> */}
                      <div
                        className="formimg"
                        style={{ display: "flex", gap: 10 }}
                      >
                        {console.log("primaryImg111", primaryImg)}
                        {prvImgs?.map((imgUrl, index) => (
                          <div className="formimg_box" key={index}>
                            <img
                              key={index}
                              onClick={() => {
                                // console.log(" working ");
                                handleShow(imgUrl);
                              }}
                              // onClick={() => {
                              //   console.log("splice working ");
                              //   removeImg(index);
                              // }}
                              src={imgUrl}
                              alt="Aircraft img"
                            />
                            <button
                              className="remove-icon"
                              onClick={(e) => removeImg(e, index)}
                            >
                              &times;
                            </button>
                            {imgUrl !== primaryImg && (
                              <button
                                onClick={(e) => frontImg(e, index)}
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "rgb(0, 123, 255)",
                                  border: "none",
                                  borderRadius: "15px",
                                  color: "#fff",
                                  padding: "4px 6px",
                                  cursor: "pointer",
                                  fontSize: "8px",
                                  width: "100%",
                                }}
                              >
                                Make Primary
                              </button>
                            )}

                            {/* <button
                              className="primary-button"
                              onClick={() => handlePrimaryAction(index)}
                              style={{
                                position: "absolute",
                                top: 5,
                                left: "50%",
                                transform: "translateX(-50%)",
                                backgroundColor: "#007bff",
                                border: "none",
                                borderRadius: "15px",
                                color: "#fff",
                                padding: "4px 6px",
                                cursor: "pointer",
                                fontSize: "8px",
                              }}
                            >
                              Primary
                            </button> */}
                            {/* <div> */}
                            {/* <Icon
                              className="closebtn"
                              icon="material-symbols:close"
                            ></Icon>
                            </div> */}
                          </div>
                        ))}
                      </div>
                      {/* prvImgs */}
                      {/* <div className="formimg">
                        <img src={aircraft?.profileImagePreview} alt="" />
                        <Icon
                          className="closebtn"
                          icon="material-symbols:close"
                        ></Icon>
                      </div>
                      <div className="formimg">
                        <img src={aircraft?.profileImagePreview} alt="" />
                        <Icon
                          className="closebtn"
                          icon="material-symbols:close"
                        ></Icon>
                      </div>
                      <div className="formimg">
                        <img src={aircraft?.profileImagePreview} alt="" />
                        <Icon
                          className="closebtn"
                          icon="material-symbols:close"
                        ></Icon>
                      </div> */}
                    </Form.Group>
                  </Col>
                </Row>
                <h4 className="formmain_head">Ground Speed</h4>
                <Row className="mb-3">
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>East - West</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="eastToWestSpeed"
                        placeholder="Enter East - West Ground Speed"
                        value={aircraft?.eastToWestSpeed}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft East - West Ground Speed.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>West - East</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="westToEastSpeed"
                        placeholder="Enter West - East Ground Speed"
                        value={aircraft?.westToEastSpeed}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft West - East Ground Speed.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h4 className="formmain_head">Direct Operation Cost</h4>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Engine Cost per hour</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="engineCostPerHour"
                        placeholder="Enter Engine Cost per hour"
                        value={aircraft?.engineCostPerHour}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Engine Cost per hour.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Fuel Cost per hour</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="fuelCostPerHour"
                        placeholder="Enter Fuel Cost per hour"
                        value={aircraft?.fuelCostPerHour}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Fuel Cost per hour.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Maintenance Cost per hour</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="maintenanceCostPerHour"
                        placeholder="Enter Maintenance Cost per hour"
                        value={aircraft?.maintenanceCostPerHour}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Maintenance Cost per hour.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Total Cost</Form.Label>
                      <Form.Control
                        readOnly
                        type="text"
                        name="totalCostPerHour"
                        value={aircraft.totalCostPerHour}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  type="submit"
                  disabled={submitting}
                >
                  Update
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal className="modalimg_div" show={show} onHide={handleClose} centered>
        <button className="remove-icon" onClick={handleClose}>
          &times;
        </button>
        <Modal.Body>
          <img src={selectedImg} alt="" />
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </React.Fragment>
    )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      const response = await postApi("adminForgotPassword", { email });
      if (response?.status) {
        let data = await response.data;
        data = { ...data, contact: email };
        console.log(data);
        toast.success("Please check email", {
          onClose: () => navigate("/OTP", { state: data }),
          autoClose: 2000, // Display toast for 3 seconds
        });
      } else {
        toast.error(response, {
          autoClose: 1000,
          onClose: () => {
            setSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Forgot Password</h2>
              <p>
                Please enter your email address to receive an otp for password
                reset.
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button
                className="btn btn-primary px-4 mt-3 min_width140"
                type="submit"
                disabled={submitting}
              >
                Proceed
              </Button>
              {/* <Link to="/OTP"> */}
              {/* <Button
                as="input"
                type="submit"
                value="Proceed"
                className="btn btn-primary w-100 mt-3"
              /> */}
              {/* </Link> */}
              <p className="formfooter text-center mt-3">
                Back To <Link to="/">Login</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </React.Fragment>
  );
}

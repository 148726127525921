import React, { useState } from "react";
import { Row, Col, Form, Card, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postApiformdata } from "./axiosInstance/axiosConfig";
import airplaneimg1 from "../assets/img/airplaneimg1.jpg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
export default function Addairplane() {
  const [aircraft, setAircraft] = useState({
    airplaneType: "",
    modelNumber: "",
    capacity: "",
    eastToWestSpeed: null,
    westToEastSpeed: null,
    engineCostPerHour: null,
    fuelCostPerHour: null,
    maintenanceCostPerHour: null,
    totalCostPerHour: null,
  });

  const navigate = useNavigate();
  const [prvImgs, setPrvImg] = useState([]);
  const [sendingImg, setSendingImg] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [validated, setValidated] = useState(false);
  const handleClose = () => {
    setSelectedImg(null);
    setShow(false);
  };

  const handleShow = (imgUrl) => {
    setSelectedImg(imgUrl);
    setShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAircraft((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const removeImg = (index) => {
    let arr = [...prvImgs];
    let sendArr = [...sendingImg];
    sendArr.splice(index, 1);
    arr.splice(index, 1);
    setPrvImg(arr);
    setSendingImg(sendArr);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const prvImg = files.map((file) => URL.createObjectURL(file));
      setPrvImg((prevPrvImg) => [...prevPrvImg, ...prvImg]);

      const newFiles = files.map((file, index) => ({
        file,
        order: sendingImg.length + index + 1, // Continue order from the last file
      }));

      setSendingImg((prevSendingImg) => [...prevSendingImg, ...newFiles]);
    }
  };

  const getTotal = () => {
    const total =
      parseInt(aircraft.engineCostPerHour || 0) +
      parseInt(aircraft.fuelCostPerHour || 0) +
      parseInt(aircraft.maintenanceCostPerHour || 0);
    return total;
  };
  const handleOnDragEnd = (result) => {
    console.log("handleOnDragEnd");
    if (!result.destination) return;

    const reorderedFiles = Array.from(sendingImg);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    // Update the order based on new position
    const updatedFiles = reorderedFiles.map((file, index) => ({
      ...file,
      order: index + 1,
    }));
    setSendingImg(updatedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      e.stopPropagation();
      setSubmitting(true);
      const formData = new FormData();
      formData.append("airplaneType", aircraft.airplaneType);
      formData.append("capacity", aircraft.capacity);
      formData.append("modelNumber", aircraft.modelNumber);
      sendingImg.forEach((val) => {
        formData.append("aircraftPic", val.file);
        formData.append("order[]", val.order);
      });
      formData.append("eastToWestSpeed", aircraft.eastToWestSpeed);
      formData.append("westToEastSpeed", aircraft.westToEastSpeed);
      formData.append("engineCostPerHour", aircraft.engineCostPerHour);
      formData.append("fuelCostPerHour", aircraft.fuelCostPerHour);
      formData.append(
        "maintenanceCostPerHour",
        aircraft.maintenanceCostPerHour
      );
      formData.append("totalCostPerHour", getTotal());

      try {
        const response = await postApiformdata("createAirplane", formData);
        if (response?.status) {
          toast.success("Aircraft added successfully", {
            onClose: () => navigate(-1),
            autoClose: 500,
          });
        } else {
          setSubmitting(false);
          toast.error(response);
          // toast.error("Failed to add aircraft");
        }
      } catch (err) {
        console.error(err);
        toast.error("An error occurred while adding the aircraft");
      } finally {
        setSubmitting(false);
      }
    }
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Add Airplane</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Airplane"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={12}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="airplaneType"
                        placeholder="Enter Type"
                        value={aircraft?.airplaneType}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide type.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Aircraft Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="modelNumber"
                        placeholder="Enter Type"
                        value={aircraft?.modelNumber}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Seats</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="capacity"
                        placeholder="Enter Seats"
                        value={aircraft?.capacity}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft Seats.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Images</Form.Label>
                      <Form.Control
                      required
                        id="categoryimg"
                        name="categoryimg"
                        className="displaynone"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        onDragEnd={handleOnDragEnd}
                      />
                      
                      <Form.Label className="fileupload" htmlFor="categoryimg">
                        <Icon
                          className="uplaodicon"
                          icon="ion:camera-outline"
                        ></Icon>
                        <h3>Please select image</h3>
                      </Form.Label>
                      <div
                        className="formimg"
                        style={{ display: "flex", gap: 10 }}
                      >
                        {console.log("sendingImg", sendingImg)}
                        {prvImgs?.map((imgUrl, index) => (
                          <div className="formimg_box" key={imgUrl.id}>
                            <img
                              key={imgUrl.id}
                              onClick={() => handleShow(imgUrl)}
                              src={imgUrl}
                              alt="Aircraft img"
                            />
                            <button
                              className="remove-icon"
                              onClick={() => removeImg(index)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId={"droppableq"} type="group">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                padding: 8,
                                width: 400,
                                minHeight: 100,
                                backgroundColor: "#f0f0f0",
                              }}
                            >
                              {sendingImg.map((img, index) => (
                                <Draggable
                                  key={img.file.name}
                                  draggableId={img.file.name}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      style={{
                                        padding: 8,
                                        width: 400,
                                        minHeight: 100,
                                        backgroundColor: "#f0f0f0",
                                      }}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="image-item">
                                        <img
                                          src={URL.createObjectURL(img.file)}
                                          alt="Preview"
                                        />
                                        <p>Order: {img.order}</p>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext> */}
                      <Form.Control.Feedback type="invalid">
                      Please provide images.
                    </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h4 className="formmain_head">Ground Speed</h4>
                <Row className="mb-3">
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>East - West</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="eastToWestSpeed"
                        placeholder="Enter East - West Ground Speed"
                        value={aircraft?.eastToWestSpeed}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft East - West Ground Speed.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>West - East</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="westToEastSpeed"
                        placeholder="Enter West - East Ground Speed"
                        value={aircraft?.westToEastSpeed}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Aircraft West - East Ground Speed.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h4 className="formmain_head">Direct Operation Cost</h4>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Engine Cost per hour</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="engineCostPerHour"
                        placeholder="Enter Engine Cost per hour"
                        value={aircraft?.engineCostPerHour}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Engine Cost per hour.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Fuel Cost per hour</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="fuelCostPerHour"
                        placeholder="Enter Fuel Cost per hour"
                        value={aircraft?.fuelCostPerHour}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Fuel Cost per hour.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Maintenance Cost per hour</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="maintenanceCostPerHour"
                        placeholder="Enter Maintenance Cost per hour"
                        value={aircraft?.maintenanceCostPerHour}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Maintenance Cost per hour.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Total Cost</Form.Label>
                      <Form.Control
                        required
                        // readOnly
                        type="number"
                        name="totalCostPerHour"
                        value={getTotal()}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  type="submit"
                  disabled={submitting}
                >
                  Save
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal className="modalimg_div" show={show} onHide={handleClose} centered>
        <button className="remove-icon" onClick={handleClose}>
          &times;
        </button>
        <Modal.Body>
          <img src={selectedImg} alt="" />
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
}

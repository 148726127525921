import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Table } from "react-bootstrap";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Sentry } from "react-activity";
export default function ViewEmptyLeg() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userinfo = {
    AircraftType: "Ultra Long Range Jet",
    From: "Temale International Airport",
    To: "Dallas Fort Worth International Airport",
    Date: "	26 June 2024",
    Time: "	09:00 AM",
    Price: "$850",
    Notes:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  };
  const { id } = useParams();
  const [leg, setLeg] = useState({
    aircraftType: "",
    From: "",
    To: "",
    Date: "",
    Time: "",
    Price: "",
    Notes: "",
  });
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = async () => {
    getApi("viewEmptyLeg?legId=" + id)
      .then((res) => {
        console.log("viewEmptyLeg", res.data);
        setLeg(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
              <h5>Empty Leg Details</h5>
              <Link
                className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
                to="/EmptyLegs"
              >
                Back
              </Link>
            </Card.Header>
            <Card.Body className="bookingtable mt-3">
              <Table responsive>
                <tbody>
                  <tr>
                    <td>
                      <b>Aircraft Type</b>
                    </td>
                    <td>{leg.aircraftType.modelNumber}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>From</b>
                    </td>
                    <td>{leg.departureAirport?.airportName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>To</b>
                    </td>
                    <td>{leg.arrivalAirport?.airportName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Departure Date</b>
                    </td>
                    {/* <td>{leg.startDate}</td> */}
                    <td>
                      {leg.startDate
                        ? moment(leg.startDate).format("YYYY-MM-DD")
                        : leg.startDate}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Departure Time</b>
                    </td>
                    <td>
                      {leg.startDate
                        ? moment(leg.startDate).format("HH:mm")
                        : leg.startDate}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Price</b>
                    </td>
                    <td>{leg.Price}</td>
                  </tr>
                  {/* <tr>
                <td>
                  <b>Notes</b>
                </td>
                <td>{leg.Notes}</td>
              </tr> */}
                  <tr>
                    <td>
                      <b>Status</b>
                    </td>
                    <td>
                      <span className="badge badge_completed">
                        {leg?.status}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </React.Fragment>
      )}
    </>
  );
}

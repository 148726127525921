import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getApi, deleteApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Sentry } from "react-activity";
export default function EmptyLeg() {
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const columns = [
    {
      name: "Aircraft",
      minWidth: "170px",
      selector: (row) => row?.aircraftType?.modelNumber,
      sortable: true,
      cell: (row) => row?.aircraftType?.modelNumber,
    },
    {
      name: "From",
      minWidth: "170px",
      selector: (row) => row.departureAirport.airportName,
      sortable: true,
      cell: (row) => row.departureAirport.airportName,
    },
    {
      name: "To",
      minWidth: "170px",
      selector: (row) => row.arrivalAirport.airportName,
      sortable: true,
      cell: (row) => row.arrivalAirport.airportName,
    },
    {
      name: "Departure Date",
      minWidth: "170px",
      selector: (row) => row.startDate,
      sortable: true,
      cell: (row) => moment(row.startDate).format("YYYY-MM-DD"),
    },
    {
      name: "Departure Time",
      minWidth: "170px",
      selector: (row) => row.startDate,
      sortable: true,
      cell: (row) => moment(row.startDate).format("HH:mm A"),
    },
    {
      name: "Price",
      selector: (row) => row.Price,
      sortable: true,
      cell: (row) => row.Price,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span className={row.status + " badge"}>{row.status}</span>
      ),
    },
    {
      name: "Actions",
      minWidth: "150px",
      cell: (row) => (
        <>
          <Link className="btnview" to={`/ViewEmptyLeg/${row._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btnedit" to={`/EditEmptyLeg/${row._id}`}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={() => handleShow(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const handleClose = () => {
    setShow(false);
    setSelectedId(null);
  };
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };
  const handleDelete = async (id) => {
    handleShow();
    await deleteApi(`deleteEmptyLeg?legId=${selectedId}`);
    handleClose();
    await fetchData();
    toast.success("leg deleted Successfully", {
      autoClose: 1000,
    });
  };
  // const [data] = useState([
  //   {
  //     id: 1,
  //     Aircraft: "Ultra Long Range Jet",
  //     From: "Temale International Airport",
  //     To: "Ultra Long Range Jet",
  //     Date: "26 June 2024",
  //     Time: "09:00 AM",
  //     Price: "$2100.00",
  //     status: "Active",
  //   },
  //   {
  //     id: 2,
  //     Aircraft: "Ultra Long Range Jet",
  //     From: "Temale International Airport",
  //     To: "Ultra Long Range Jet",
  //     Date: "26 June 2024",
  //     Time: "09:00 AM",
  //     Price: "$2100.00",
  //     status: "Active",
  //   },
  //   {
  //     id: 3,
  //     Aircraft: "Ultra Long Range Jet",
  //     From: "Temale International Airport",
  //     To: "Ultra Long Range Jet",
  //     Date: "26 June 2024",
  //     Time: "09:00 AM",
  //     Price: "$2100.00",
  //     status: "Inactive",
  //   },
  //   {
  //     id: 4,
  //     Aircraft: "Ultra Long Range Jet",
  //     From: "Temale International Airport",
  //     To: "Ultra Long Range Jet",
  //     Date: "26 June 2024",
  //     Time: "09:00 AM",
  //     Price: "$2100.00",
  //     status: "Active",
  //   },
  //   {
  //     id: 5,
  //     Aircraft: "Ultra Long Range Jet",
  //     From: "Temale International Airport",
  //     To: "Ultra Long Range Jet",
  //     Date: "26 June 2024",
  //     Time: "09:00 AM",
  //     Price: "$2100.00",
  //     status: "Active",
  //   },
  // ]);
  useEffect(() => {
    fetchData();
  }, [page, perPage, searchText]);
  const handlePageChange = (page) => {
    console.log("Dsdsdsdsd", page);
    setPage(page);
  };
  // Filter data based on search text
  // const filteredData = data.filter(
  //   (item) =>
  //     JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) !==
  //     -1
  // );
  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("Dsdsdsdsd1", newPerPage, page);
    setPage(page);
    setPerPage(newPerPage);
  };
  const fetchData = async () => {
    getApi("getEmptyLegList", page, perPage, searchText)
      .then((res) => {
        console.log("getEmptyLegList", res.data.list);
        // if (res?.data?.list.length > 0) {
        //   setList(res?.data?.list ?? []);
        // }
        setList(res.data.list);
        setTotalRows(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h5>Empty Leg</h5>
                  <Button
                    className="btn btn-primary px-4 min_width140"
                    as={Link}
                    to="/AddEmptyLeg"
                  >
                    Add
                  </Button>
                </Card.Header>
                <Card.Body>
                  {/* <div className="mb-3">
                <Row>
                  <Col md={6}>
                    <Form.Group className="filtersort_group">
                      <Form.Label>Sort</Form.Label>
                      <Form.Select aria-label="Select Status">
                        <option value="select">Select Sort</option>
                        <option value="1">Date</option>
                        <option value="2">Route</option>
                        <option value="2">Status</option>
                        <option value="2">Aircraft Type</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="text-end">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="searchfield"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Col>
                </Row>
              </div> */}
                  <DataTable
                    columns={columns}
                    data={list}
                    pagination
                    responsive
                    progressPending={loading}
                    paginationServer
                    paginationPerPage={perPage}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationRowsPerPageOptions={[5, 10, 20]}
                    onChangePage={handlePageChange}
                    className="custom-table"
                  />
                </Card.Body>
              </Card>
              <ToastContainer />
            </Col>
          </Row>
          <Modal
            className="modal_Delete"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Body>
              <div className="modaldelete_div">
                <Icon className="delete_icon" icon="gg:close-o"></Icon>
                <h3>Are You Sure ?</h3>
                <p>You will not be able to recover the deleted record!</p>
              </div>
              <Button
                onClick={handleClose}
                className="btn btn-secondary px-4 me-3"
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary px-4 min_width110"
                onClick={handleDelete}
              >
                Ok
              </Button>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
    </>
  );
}

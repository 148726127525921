import React, { useEffect, useState } from "react";
import { Card, Table } from 'react-bootstrap';
import { Link,useParams } from 'react-router-dom';
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import moment from 'moment';
import { Sentry } from "react-activity";
export default function Viewpayment() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [detail, setDetail] = useState({
    transactionId: "",
    fullName: "",
    total: "",
    bookingDate: "",
    PaymentMethod: 'Credit card',
  });
  const userinfo = {
    TransactionID: '#12345678',
    UserName: 'Maria James',
    PaymentMethod: 'Credit card',
    Amount: '$850',
    Date: '	April 21, 2024',
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = async () => {
    getApi("adminPaymentDetail?bookingId=" + id)
      .then((res) => {
        console.log("paymentdetail",res.data);
        setDetail(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Payment Details</h5>
          <Link className="btn btn-primary d-inline-flex align-items-center px-4 min_width140" to="/Payments">Back</Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Table responsive>
            <tbody>
              <tr>
                <td><b>Transaction ID</b></td>
                <td>{detail?.paymentDetail?.transactionId}</td>
              </tr>
              <tr>
                <td><b>User Name</b></td>
                <td>{detail?.userId?.fullName}</td>
              </tr>
              {/* <tr>
                <td><b>Payment Method</b></td>
                <td>{detail?.paymentDetail?.paymentMethodId}</td>
              </tr> */}
              <tr>
                <td><b>Amount</b></td>
                <td>{detail?.total ? `$${detail.total.toFixed(2)}` : ''}</td>
              </tr>
              <tr>
                <td><b>Date</b></td>
                <td>{moment(detail?.bookingDate).format("YYYY-MM-DD HH:mm")}</td>
              </tr>
              <tr>
                <td><b>Status</b></td>
                <td><span className="badge badge_completed">{detail?.paymentDetail?.paymentStatus}</span></td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
    )}
    </>
  );

}
import React, { useEffect, useState } from "react";
import { Card, Button, Modal, Form } from "react-bootstrap";
import { getApi, deleteApi, postApi } from "./axiosInstance/axiosConfig";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Sentry } from "react-activity";
export default function Notifications() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [list, setList] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [deleted, setDeleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handlePageChange = (page) => {
    console.log("Dsdsdsdsd", page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("Dsdsdsdsd1", newPerPage, page);
    setPage(page);
    setPerPage(newPerPage);
  };
  useEffect(() => {
    getnotificationlist(page, perPage);
  }, [page, perPage, deleted, loadings]);
  const getnotificationlist = (page, perPage) => {
    getApi("getAdminNotification", page, perPage)
      .then((res) => {
        console.log(res.data.list);
        setList(res.data.list);

        setTotalRows(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  };
  const Data = [
    {
      id: 1,
      name: "Rodeo",
      description:
        "Your booking has been done successfully for date: 12/03/23 and time: 10:00am",
      time: "10 min ago",
    },
    {
      id: 2,
      name: "Rodeo",
      description:
        "Your booking has been done successfully for date: 12/03/23 and time: 10:00am",
      time: "2 hours ago",
    },
    {
      id: 3,
      name: "Rodeo",
      description:
        "Your booking has been done successfully for date: 12/03/23 and time: 10:00am",
      time: "1 day ago",
    },
    {
      id: 4,
      name: "Rodeo",
      description:
        "Your booking has been done successfully for date: 12/03/23 and time: 10:00am",
      time: "2 days ago",
    },
    {
      id: 5,
      name: "Rodeo",
      description:
        "Your booking has been done successfully for date: 12/03/23 and time: 10:00am",
      time: "4 days ago",
    },
  ];
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => <>{row.title}</>,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
      cell: (row) => row.message,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
      cell: (row) => new Date(row.createdAt).toLocaleDateString('en-CA'),
    },
  ];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // setLoadings(true)
      console.log("notificationForm working");
      const response = await postApi("sendNotification", { message });
      console.log(response, "response");
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        setMessage("");
        getnotificationlist(page, perPage);
        toast.success("Notification send Successful", {
          onClose: () => navigate("/Notifications"), // Navigate after toast is closed
          autoClose: 100, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
      // setLoadings(true)
      // toast.success("Edit Successful", {
      //   autoClose: 1000, // Display toast for 3 seconds
      // });
      // setError("");
      //   setLoadings(false)
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    } finally {
      // setLoadings(false)
    }
  };
  console.log("loading---------", loadings);
  return (
    <>
    {loading ? (
      <div className="loaderdiv">
        <Sentry color="#003CBF" size={40} speed={1} animating={true} />
      </div>
    ) : (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Notifications</h5>
          <Button
            className="btn btn-primary px-4 min_width140"
            onClick={handleShow}
          >
            Add
          </Button>
        </Card.Header>
        {/* <Card.Body>
          {list.map((item, index) => (
            <div key={index} className="notificationlist mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h2>{item?.title}</h2>{" "}
                <span className="notifytime">
                  <Icon icon="tabler:clock" />
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </span>
              </div>
              <p>{item.isAdmin ? item.message : item?.payload?.adminMessage}</p>
            </div>
          ))}
        </Card.Body> */}
        <Card.Body>
              {/* <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div> */}
              <DataTable
                columns={columns}
                data={list}
                pagination
                responsive
                progressPending={loading}
                paginationServer
                paginationPerPage={perPage}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationRowsPerPageOptions={[5, 10, 20]}
                onChangePage={handlePageChange}
                className="custom-table"
              />
            </Card.Body>
      </Card>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Send Notification</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group className="mb-3 form-group">
                            <Form.Label>Search User</Form.Label>
                            <Form.Control type="text" placeholder="Search User" />
                        </Form.Group> */}
            <Form.Group className="mb-3 form-group">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
            <Button
              onClick={handleClose}
              className="btn btn-secondary px-4 min_width110"
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary px-4 mx-2 min_width110"
              type="submit"
              onClick={handleClose}
            >
              Send
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
    )}
  </>
  );
}

import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import moment from "moment";
import { Sentry } from "react-activity";
export default function Viewbooking() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [detail, setDetail] = useState({
    name: "",
    email: "",
    Phone: "",
    fightname: "",
    Passenger: "",
    From: "",
    To: "",
    DepartureTime: "",
    ArrivalTime: "",
    Price: "",
    Date: "",
  });
  const userinfo = {
    name: "Maria James",
    email: "maris@gmail.com",
    Phone: "+01 123 456 789",
    fightname: "Global 6000",
    Passenger: "14",
    From: "Temale International Airport",
    To: "Kumasi International Airport",
    DepartureTime: "09:00 AM",
    ArrivalTime: "05:15 PM",
    Price: "$850",
    Date: "27 April 2024",
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = async () => {
    getApi("adminBookingDetail?bookingId=" + id)
      .then((res) => {
        console.log(res.data);
        setDetail(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  };
  return (
    <>
      {console.log("loading", loading)}
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
              <h5>Booking Details</h5>
              <Link
                className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
                to="/Booking"
              >
                Back
              </Link>
            </Card.Header>
            <Card.Body className="bookingtable mt-3">
              <div className="bookdtl_list">
                <h4 className="main_head">User Info</h4>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td width="30%">
                        <b>Name</b>
                      </td>
                      <td>
                        {detail.userId ? detail.userId.fullName : detail.name}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>Email</b>
                      </td>
                      <td>
                        {detail.userId ? detail.userId.email : detail.email}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>Phone No.</b>
                      </td>
                      <td>
                        {detail.userId
                          ? detail.userId.phoneNumber
                          : detail.Phone}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="bookdtl_list">
                <h4 className="main_head">Flight Detail</h4>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td width="30%">
                        <b>Flight Name</b>
                      </td>
                      <td>
                        {detail.aircraftId
                          ? detail.aircraftId.modelNumber
                          : detail.fightname}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>Passenger</b>
                      </td>
                      <td>
                        {detail.outboundFlight
                          ? detail.outboundFlight.noOfPassenger
                          : detail.From}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>(Outbound) From</b>
                      </td>
                      <td>
                        {detail.outboundFlight
                          ? detail.outboundFlight.departureAPairportName
                          : detail.From}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>(Outbound) To</b>
                      </td>
                      <td>
                        {detail.outboundFlight
                          ? detail.outboundFlight.arrivalAPairportName
                          : detail.From}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>(Outbound) Departure Time</b>
                      </td>
                      <td>
                        {detail.outboundFlight
                          ? moment(
                              detail.outboundFlight.departureGoDate
                            ).format("HH:mm A")
                          : detail.From}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>(Outbound) Arrival Time</b>
                      </td>
                      <td>
                        {detail.outboundFlight
                          ? moment(detail.outboundFlight.arrivalGoDate).format(
                              "HH:mm A"
                            )
                          : detail.From}
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <b>(Outbound) Date</b>
                      </td>
                      <td>
                        {detail.outboundFlight
                          ? moment(detail.outboundFlight.outboundDate).format(
                              "YYYY-MM-DD"
                            )
                          : detail.From}
                      </td>
                    </tr>
                    {detail.type == "twoWay" ? (
                      <>
                        <tr>
                          <td width="30%">
                            <b>(Return) From</b>
                          </td>
                          <td>
                            {detail.outboundFlight
                              ? detail.outboundFlight.departureAPairportName
                              : detail.From}
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <b>(Return) To</b>
                          </td>
                          <td>
                            {detail.outboundFlight
                              ? detail.outboundFlight.arrivalAPairportName
                              : detail.From}
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <b>(Return) Departure Time</b>
                          </td>
                          <td>
                            {detail.outboundFlight
                              ? moment(
                                  detail.returnFlight.departurereturnDate
                                ).format("HH:mm A")
                              : detail.From}
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <b>(Return) Arrival Time</b>
                          </td>
                          <td>
                            {detail.outboundFlight
                              ? moment(
                                  detail.returnFlight.departurereturnDate
                                ).format("HH:mm A")
                              : detail.From}
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <b>(Return) Date</b>
                          </td>
                          <td>
                            {detail.returnFlight
                              ? moment(detail.returnFlight.returnDate).format(
                                  "YYYY-MM-DD"
                                )
                              : detail.From}
                          </td>
                        </tr>
                      </>
                    ) : null}

                    <tr>
                      <td width="30%">
                        <b>Price</b>
                      </td>
                      <td>{`$${
                        detail.total ? detail.total.toFixed(2) : detail.Price
                      }`}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </React.Fragment>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Card, Table, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import avatarimg1 from "../assets/img/avatars/1.png";
import { Icon } from "@iconify/react";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { S3_BASE_URL } from "../helper/config";
import avatardefault from "../assets/img/default.jpg";
import { Sentry } from "react-activity";
export default function Viewuser() {
  const { id } = useParams();
  const [user, setUser] = useState({
    name: "",
    email: "",
    Phone: "",
    image: avatardefault,
    status: "",
  });
  const [list, setlist] = useState([]);
  const [key, setKey] = useState("basicinfo");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const senddata = [
  //   {
  //     id: 1,
  //     flightname: "Global 6000",
  //     From: "Temale International Airport",
  //     To: "Kumasi International Airport",
  //     Price: "$850",
  //   },
  //   {
  //     id: 2,
  //     flightname: "Global 6000",
  //     From: "Temale International Airport",
  //     To: "Kumasi International Airport",
  //     Price: "$850",
  //   },
  //   {
  //     id: 3,
  //     flightname: "Global 6000",
  //     From: "Temale International Airport",
  //     To: "Kumasi International Airport",
  //     Price: "$850",
  //   },
  //   {
  //     id: 4,
  //     flightname: "Global 6000",
  //     From: "Temale International Airport",
  //     To: "Kumasi International Airport",
  //     Price: "$850",
  //   },
  //   {
  //     id: 5,
  //     flightname: "Global 6000",
  //     From: "Temale International Airport",
  //     To: "Kumasi International Airport",
  //     Price: "$850",
  //   },
  // ];
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = async () => {
    getApi("admingetUser?userId=" + id)
      .then((res) => {
        // console.log(res.data);
        setUser(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
    getApi("adminUserBookingList?userId=" + id)
      .then((res) => {
        // console.log("booking", res.data.list);
        setlist(res.data.list);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
              <h5>User Details</h5>
              <Link
                className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
                to="/Users"
              >
                Back
              </Link>
            </Card.Header>
            <Card.Body className="bookingtable mt-3">
              <Tabs
                defaultActiveKey="basicinfo"
                id="uncontrolled-tab-example"
                className="customtabs mb-2"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="basicinfo" title="Basic Info">
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td>
                          <b>Name</b>
                        </td>
                        <td>{user.fullName}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Email</b>
                        </td>
                        <td>{user.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Phone No.</b>
                        </td>
                        <td>{user.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Status</b>
                        </td>
                        <td>
                          <span className={user.accountStatus + " badge"}>
                            {user.accountStatus}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Image</b>
                        </td>
                        <td>
                          <img
                            className="dtlimg img-thumbnail"
                            src={
                              user.profilePic
                                ? `${S3_BASE_URL}${user.profilePic}`
                                : avatardefault
                            }
                            alt=""
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="Booking" title="Booking">
                  <div className="userbooking_table">
                    <Table>
                      <thead>
                        <tr>
                          <th>Flight Name</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.length > 0 ? (
                          list.map((item, index) => (
                            <tr>
                              <td>{item.aircraftId.modelNumber}</td>
                              <td>
                                {item.outboundFlight.departureAPairportName}
                              </td>
                              <td>
                                {item.outboundFlight.arrivalAPairportName}
                              </td>
                              <td>{`$${item.total.toFixed(2)}`}</td>
                              <td>
                                <Link
                                  className="btnview"
                                  to={`/Viewbooking/${item._id}`}
                                >
                                  <Icon icon="tabler:eye"></Icon>
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </React.Fragment>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import logodark from "../assets/img/logodark.svg";
import { Icon } from "@iconify/react";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    document.body.classList.toggle("no-scroll", sidebarOpen);
  }, [sidebarOpen]);

  const handleNavLinkClick = () => {
    setSidebarOpen(false);
  };

  const pages = [
    {
      id: "Dashboard",
      title: "Dashboard",
      link: "/Dashboard",
      icon: "mdi:view-grid-outline",
    },
    {
      id: "Users",
      title: "Users",
      link: "/Users",
      icon: "lucide:users",
    },
    // {
    //   id: "Airports",
    //   title: "Airports",
    //   link: "/Airports",
    //   icon: "streamline:airport-plane-transit",
    // },
    {
      id: "Airplane",
      title: "Airplane",
      link: "/Airplane",
      icon: "lucide:plane-takeoff",
    },
    {
      id: "EmptyLegs",
      title: "Empty Legs",
      link: "/EmptyLegs",
      icon: "lucide:plane-takeoff",
    },
    // {
    //   id: 'Flights',
    //   title: 'Flights',
    //   link: '/Flights',
    //   icon: 'carbon:flight-schedule',
    // },
    // {
    //   id: "Positioningleg",
    //   title: "Positioning leg",
    //   link: "/Positioningleg",
    //   icon: "carbon:flight-schedule",
    // },
    {
      id: "Booking",
      title: "Booking",
      link: "/Booking",
      icon: "ion:calendar-outline",
    },
    {
      id: "Payments",
      title: "Payments",
      link: "/Payments",
      icon: "fluent-mdl2:payment-card",
    },
    // {
    //   id: "GrossProfit",
    //   title: "Gross Profit",
    //   link: "/GrossProfit",
    //   icon: "solar:hand-money-outline",
    // },
    // {
    //   id: "AddTaxes",
    //   title: "Add Taxes",
    //   link: "/AddTaxes",
    //   icon: "tabler:receipt-tax",
    // },
    {
      id: "Notifications",
      title: "Notifications",
      link: "/Notifications",
      icon: "mingcute:notification-line",
    },
    {
      id: "Pages",
      title: "All Pages",
      link: "/Allpages",
      icon: "bx:file",
    },
    {
      id: "Settings",
      title: "Global Settings",
      link: "/Settings",
      icon: "solar:settings-outline",
    },
    {
      id: "ProfileSettings",
      title: "Profile Settings",
      link: "/ProfileSettings",
      icon: "solar:settings-outline",
    },
    {
      id: "Checkout",
      title: "Check out",
      link: "/Checkout",
      icon: "ion:calendar-outline",
    },
  ];
  return (
    <>
      <button
        onClick={toggleSidebar}
        className="btnopen"
        style={{ display: "none" }}
      >
        <Icon icon="solar:hamburger-menu-broken"></Icon>
      </button>
      <button
        className={
          sidebarOpen ? "btnclose sidebarbg-open" : "btnclose sidebarbg-closed"
        }
        style={{ display: "none" }}
        onClick={toggleSidebar}
      ></button>

      <div
        className={
          sidebarOpen ? "sidebar sidebar-open" : "sidebar sidebar-closed"
        }
      >
        <div className="sidebarlogo_div">
          <Link to="/Dashboard">
            <img src={logodark} alt="" />
          </Link>
        </div>
        <div className="sidebarouter">
          {pages.map((page) => (
            <NavLink
              key={page.id}
              to={page.link}
              className="navitem"
              onClick={handleNavLinkClick}
            >
              <Icon icon={page.icon} />
              {page.title}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Modal, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import avatar1 from "../assets/img/avatars/1.png";
import avatar2 from "../assets/img/avatars/2.png";
import avatar3 from "../assets/img/avatars/3.png";
import avatar4 from "../assets/img/avatars/4.png";
import avatar5 from "../assets/img/avatars/5.png";
import avatardefault from "../assets/img/default.jpg";
import { Sentry } from "react-activity";
import {
  getApi,
  deleteApi,
  getDashboardApi,
} from "./axiosInstance/axiosConfig";
import { S3_BASE_URL } from "../helper/config";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
const data2 = [
  {
    name: "Jan",
    Value: 10,
  },
  {
    name: "Feb",
    Value: 50,
  },
  {
    name: "Mar",
    Value: 150,
  },
  {
    name: "Apr",
    Value: 100,
  },
  {
    name: "May",
    Value: 250,
  },
  {
    name: "Jun",
    Value: 400,
  },
  {
    name: "July",
    Value: 500,
  },
  {
    name: "Aug",
    Value: 300,
  },
  {
    name: "Sep",
    Value: 210,
  },
  {
    name: "Oct",
    Value: 115,
  },
  {
    name: "Nov",
    Value: 360,
  },
  {
    name: "Dec",
    Value: 450,
  },
];

const data1 = [
  {
    _id: "Jan",
    count: 30,
  },
  {
    _id: "Feb",
    count: 20,
  },
  {
    _id: "Mar",
    count: 150,
  },
  {
    _id: "Apr",
    count: 230,
  },
  {
    _id: "May",
    count: 300,
  },
  {
    _id: "Jun",
    count: 200,
  },
  {
    _id: "July",
    count: 150,
  },
  {
    _id: "Aug",
    count: 340,
  },
  {
    _id: "Sep",
    count: 450,
  },
  {
    _id: "Oct",
    count: 231,
  },
  {
    _id: "Nov",
    count: 350,
  },
  {
    _id: "Dec",
    count: 401,
  },
];
const gradientColors = ["#003CBF ", "#fff"];
const formatYAxisTick = (value) => value;
const formatYAxisTick2 = (value) => `$ ${value}`;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{` ${payload[0].value} Users`}</p>
      </div>
    );
  }

  return null;
};
const CustomTooltip2 = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`$ ${payload[0].value} `}</p>
      </div>
    );
  }

  return null;
};
export default function Dashboard() {
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedId(null);
  };
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };
  const handleDelete = (id) => {
    console.log("id", id);
    handleShow();
    deleteApi(`deleteUser?userId=${selectedId}`);
    handleClose();
    setDeleted(true);
    // handleAnotherEvent();
  };
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState(data1);
  const [earningList, setEarningList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalBooking, setTotalBooking] = useState(0);
  const [totalNotification, setTotalNotification] = useState(0);
  const [userFilter, setUserFilter] = useState("year");
  const [earnFilter, setEarnFilter] = useState("year");
  const handleUserFilterChange = (e) => {
    const filterValue = e.target.value;
    if (filterValue === "1") {
      setUserFilter("week");
    } else if (filterValue === "2") {
      setUserFilter("month");
    } else {
      setUserFilter("year");
    }
  };
  const handleEarningFilterChange = (e) => {
    const filterValue = e.target.value;
    if (filterValue === "1") {
      setEarnFilter("week");
    } else if (filterValue === "2") {
      setEarnFilter("month");
    } else {
      setEarnFilter("year");
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-2"
            src={
              row.profilePic ? `${S3_BASE_URL}${row.profilePic}` : avatardefault
            }
            alt={row.profilePic}
          />
          {row.fullName}
        </>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      // cell: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => row.accountStatus,
      sortable: false,
      cell: (row) => (
        <span className={row.accountStatus + " badge"}>
          {row.accountStatus}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => (
        <>
          <Link className="btnview" to={`/Viewuser/${row._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btnedit" to={`/Edituser/${row._id}`}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={() => handleShow(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const [data] = useState([
    {
      id: 1,
      name: "Maria James",
      imgurl: avatar1,
      email: "maris@gmail.com",
      status: "Active",
    },
    {
      id: 2,
      name: "Johnson Charles",
      imgurl: avatar2,
      email: "johson@gmail.com",
      status: "Inactive",
    },
    {
      id: 3,
      name: "David Smith",
      imgurl: avatar3,
      email: "david@gmail.com",
      status: "Active",
    },
    {
      id: 4,
      name: "Steve Jones",
      imgurl: avatar4,
      email: "steve@gmail.com",
      status: "Inactive",
    },
    {
      id: 5,
      name: "James Vince",
      imgurl: avatar5,
      email: "jamese@gmail.com",
      status: "Active",
    },
  ]);

  // Filter data based on search text
  const filteredData = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) !==
      -1
  );
  useEffect(() => {
    getDashboardApi(
      "dashBoard",
      page,
      perPage,
      searchText,
      userFilter,
      earnFilter
    )
      .then((res) => {
        console.log("rsp11", res.data.usersData);
        setUsers(res.data.list);
        setUserList(res.data.usersData);
        setEarningList(res.data.earnData);
        setTotalUsers(res.data.totalUser);
        setTotalBooking(res.data.totalBooking);
        setTotalNotification(res.data.totalNotification);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, [page, perPage, searchText, userFilter, earnFilter]);
  const handlePageChange = (page) => {
    console.log("Dsdsdsdsd", page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("Dsdsdsdsd1", newPerPage, page);
    setPage(page);
    setPerPage(newPerPage);
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Row>
            <Col lg={4}>
              <div className="stats">
                <span className="statsicon">
                  <Icon icon="tabler:users" />
                </span>
                <div className="stats-cont">
                  <h4>Total Users</h4>
                  <p>{totalUsers}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="stats">
                <span className="statsicon statsblue">
                  {" "}
                  <Icon icon="ion:calendar-outline" />
                </span>
                <div className="stats-cont">
                  <h4>Total Booking</h4>
                  <p>{totalBooking}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="stats">
                <span className="statsicon statsgreen">
                  <Icon icon="mingcute:notification-line" />
                </span>
                <div className="stats-cont">
                  <h4>Notifications</h4>
                  <p>{totalNotification}</p>
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <Card className="mt-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h5>Total Users</h5>{" "}
                  <Form.Select
                    className="maxwidth120"
                    onChange={handleUserFilterChange}
                    defaultValue="3"
                    value={
                      userFilter === "week"
                        ? "1"
                        : userFilter === "month"
                        ? "2"
                        : "3"
                    }
                  >
                    <option value="1">Weekly</option>
                    <option value="2" selected>
                      Monthly
                    </option>
                    <option value="3">Yearly</option>
                  </Form.Select>
                </Card.Header>
                <Card.Body>
                  <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                      data={userList}
                      margin={{
                        top: 20,
                        right: 0,
                        left: -20,
                        bottom: 0,
                      }}
                    >
                      {" "}
                      <defs>
                        <linearGradient
                          id="areaGradient"
                          x1="0"
                          y1="1"
                          x2="0"
                          y2="0"
                        >
                          <stop
                            offset="5%"
                            stopColor={gradientColors[0]}
                            stopOpacity={0.5}
                          />
                          <stop
                            offset="95%"
                            stopColor={gradientColors[1]}
                            stopOpacity={0.1}
                          />
                        </linearGradient>
                      </defs>
                      <CartesianGrid stroke="#EAEAEA" strokeWidth={1} />
                      <XAxis
                        dataKey="_id"
                        stroke="none"
                        tick={{ fill: "black", fontSize: 10 }}
                      />
                      <YAxis
                        dataKey="count"
                        tickFormatter={formatYAxisTick}
                        stroke="none"
                        tick={{ fill: "black", fontSize: 10 }}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <Area
                        type="monotone"
                        dataKey="count"
                        stroke="#003CBF " // Dark curve line color
                        fill="url(#areaGradient)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="mt-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h5>Total Earnings</h5>{" "}
                  <Form.Select
                    className="maxwidth120"
                    onChange={handleEarningFilterChange}
                    defaultValue="3"
                    value={
                      earnFilter === "week"
                        ? "1"
                        : earnFilter === "month"
                        ? "2"
                        : "3"
                    }
                  >
                    <option value="1">Weekly</option>
                    <option value="2" selected>
                      Monthly
                    </option>
                    <option value="3">Yearly</option>
                  </Form.Select>
                </Card.Header>
                <Card.Body>
                  <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                      data={earningList}
                      margin={{
                        top: 20,
                        right: 0,
                        left: -10,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid stroke="#EAEAEA" strokeWidth={1} />
                      <XAxis
                        dataKey="_id"
                        stroke="none"
                        tick={{ fill: "black", fontSize: 10 }}
                      />
                      <YAxis
                        dataKey="count"
                        tickFormatter={formatYAxisTick2}
                        stroke="none"
                        tick={{ fill: "black", fontSize: 10 }}
                      />
                      <Tooltip content={<CustomTooltip2 />} />
                      <Area
                        type="monotone"
                        dataKey="count"
                        stroke="#7367f0"
                        fill="#b09ff5"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12}>
              <Card className="mt-4">
                <Card.Header className="py-3">
                  <h5>Recent Users</h5>
                </Card.Header>
                <Card.Body>
                  <div className="text-end mb-3">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="searchfield"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={users}
                    pagination
                    responsive
                    progressPending={loading}
                    paginationServer
                    paginationPerPage={perPage}
                    paginationTotalRows={totalUsers}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationRowsPerPageOptions={[5, 10, 20]}
                    onChangePage={handlePageChange}
                    className="custom-table"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Modal
            className="modal_Delete"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Body>
              <div className="modaldelete_div">
                <Icon className="delete_icon" icon="gg:close-o"></Icon>
                <h3>Are You Sure ?</h3>
                <p>You will not be able to recover the deleted record!</p>
              </div>
              <Button
                onClick={handleClose}
                className="btn btn-secondary px-4 me-3"
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary px-4 min_width110"
                onClick={handleDelete}
              >
                Ok
              </Button>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Row, Col, Tab, Tabs, Form, Card, Button } from "react-bootstrap";
import Avatar from "../assets/img/userimg1.jpg";
import { getApi, postApi, postApiformdata } from "./axiosInstance/axiosConfig";
import { S3_BASE_URL } from "../helper/config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatardefault from "../assets/img/default.jpg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Sentry } from "react-activity";
export default function ProfileSettings() {
  // const [user, setUser] = useState(null);
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    profilePic: null,
    // profileImage: null,
    profileImagePreview: avatardefault,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordnew, setShowPasswordnew] = useState(false);
  const [showPasswordconfirm, setShowPasswordconfirm] = useState(false);
  let [password, setPasssword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("John Smith");
  const [email, setEmail] = useState("johndoe@gmail.com");
  const [phone, setPhone] = useState("+01 1234 5678 901");
  const [message, setMessage] = useState("");
  const [key, setKey] = useState("basicinfo");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasssword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePreviewUrl = URL.createObjectURL(file);
      setUser((prevData) => ({
        ...prevData,
        profilePic: file,
        profileImagePreview: imagePreviewUrl,
      }));
    }
  };

  useEffect(() => {
    getApi("adminProfile")
      .then((res) => {
        console.log("res", res.data);
        setUser({
          ...res.data,
          profileImagePreview: res.data
            ? `${S3_BASE_URL}${res.data.profilePic}`
            : avatardefault,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      const formData = new FormData();
      formData.append("fullName", user.fullName);
      formData.append("email", user.email);
      formData.append("phoneNumber", user.phoneNumber);
      formData.append("profileFile", user.profilePic);

      try {
        const response = await postApiformdata("editAdminProfile", formData);
        if (response?.status) {
          const data = await response.data;
          console.log(data);
          localStorage.setItem(
            "rodeouserData",
            JSON.stringify({ ...user, profilePic: data.profilePic })
          ); // Save token in localStorage
          toast.success("Profile edit Successfully", {
            autoClose: 100, // Display toast for 3 seconds
          });
        } else {
          setSubmitting(false);
          toast.error(response);
        }
        window.location.reload();
      } catch (err) {
        console.error(err);
        setMessage("Failed to update profile");
      }
    }
  };
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (form.checkValidity() === true) {
      const passwordobj = {
        currentPassword: password.currentPassword,
        newPassword: password.newPassword,
        confirmPassword: password.confirmPassword,
      };

      try {
        setSubmitting(true);
        const response = await postApi("adminChangePassword", passwordobj);
        // const response = await axios.post(
        //   "http://54.152.167.122/v1/api/insertProfit",
        //   grossProfit
        // );
        console.log("response", response.data);
        if (response?.status) {
          const data = await response.data;
          console.log(data);
          toast.success("Password edit SuccessfulLY", {
            autoClose: 100, // Display toast for 3 seconds
            onClose: () => {
              setSubmitting(false);
            },
          });
        } else {
          setSubmitting(false);
          toast.error(response);
        }
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        setError("There was an error submitting the form!");
        toast.error("Something wrong", {
          autoClose: 1000, // Display toast for 3 seconds
        });
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilitynew = () => {
    setShowPasswordnew(!showPasswordnew);
  };
  const togglePasswordVisibilityconfirm = () => {
    setShowPasswordconfirm(!showPasswordconfirm);
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Card>
            <Card.Header className="py-4">
              <h5>Profile Settings</h5>
            </Card.Header>
            <Card.Body>
              <Tabs
                defaultActiveKey="basicinfo"
                id="uncontrolled-tab-example"
                className="customtabs mb-2"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="basicinfo" title="Basic Info">
                  <Row>
                    <Col md={5} xl={4} className="view_profile mb-3 ">
                      <div className="user_info text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <img
                            src={user.profileImagePreview}
                            className="rounded-circle img-fluid profilepic"
                            alt=""
                          />
                        </div>
                        <label
                          htmlFor="uploadprofileimg"
                          className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                        >
                          Choose Picture
                        </label>
                        <input
                          name="profilePic"
                          type="file"
                          accept="image/*"
                          id="uploadprofileimg"
                          onChange={handleImageChange}
                          className="d-none"
                        />
                      </div>
                    </Col>
                    <Col md={7} xl={8}>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="form-group mb-3">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                name="fullName"
                                placeholder="Enter name"
                                value={user?.fullName}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="form-group mb-3">
                              <Form.Label>Email Address</Form.Label>
                              <Form.Control
                                required
                                type="email"
                                name="email"
                                placeholder="Enter email address"
                                value={user?.email}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid email.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="form-group mb-3">
                              <Form.Label>Phone No.</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                name="phoneNumber"
                                placeholder="Enter phone no."
                                value={user?.phoneNumber}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide Phone No.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="btn btn-primary px-4 mt-3"
                          type="submit"
                        >
                          Update
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="changepassword" title="Change Password">
                  <Row>
                    <Col md={8} xl={9}>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handlePasswordSubmit}
                      >
                        <Row>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group mb-3"
                              style={{ position: "relative", height: "100px" }}
                            >
                              <Form.Label>Current Password</Form.Label>
                              <Form.Control
                                required
                                type={showPassword ? "text" : "password"}
                                name="currentPassword"
                                value={password?.currentPassword}
                                placeholder="Current Password"
                                onChange={handlePasswordChange}
                              />
                              <span
                                onClick={togglePasswordVisibility}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              >
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                              </span>
                              <Form.Control.Feedback type="invalid">
                                Please provide current password.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group mb-3"
                              style={{ position: "relative", height: "100px" }}
                            >
                              <Form.Label>New Password</Form.Label>
                              <Form.Control
                                required
                                type={showPasswordnew ? "text" : "password"}
                                name="newPassword"
                                placeholder="New Password"
                                value={password?.newPassword}
                                onChange={handlePasswordChange}
                              />
                              <span
                                onClick={togglePasswordVisibilitynew}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              >
                                {showPasswordnew ? <FaEye /> : <FaEyeSlash />}
                              </span>
                              <Form.Control.Feedback type="invalid">
                                Please provide new password.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group mb-3"
                              style={{ position: "relative", height: "100px" }}
                            >
                              <Form.Label>Confirm New Password</Form.Label>
                              <Form.Control
                                required
                                type={showPasswordconfirm ? "text" : "password"}
                                name="confirmPassword"
                                placeholder="Confirm New Password"
                                value={password?.confirmPassword}
                                onChange={handlePasswordChange}
                              />
                              <span
                                onClick={togglePasswordVisibilityconfirm}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              >
                                {showPasswordconfirm ? (
                                  <FaEye />
                                ) : (
                                  <FaEyeSlash />
                                )}
                              </span>
                              <Form.Control.Feedback type="invalid">
                                Please confirm password.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="btn btn-primary px-4 mt-3"
                          type="Submit"
                          disabled={submitting}
                        >
                          Update
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
          <ToastContainer />
        </React.Fragment>
      )}
    </>
  );
}

import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import logo from "../assets/img/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../helper/API_URL";
import { login } from "./axiosInstance/axiosConfig";
import { postApi } from "./axiosInstance/axiosConfig";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Sentry } from "react-activity";
export default function Login() {
  // const [email, setEmail] = useState("rodeoadmin@yopmail.com");
  // const [password, setPassword] = useState("12345678");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      e.stopPropagation();

      try {
        setSubmitting(true);
        const response = await postApi("adminLogin", { email, password });
        console.log("response", response);
        if (response?.status) {
          setLoading(true);
          const data = await response.data;
          console.log(data);
          localStorage.setItem("rodeouserData", JSON.stringify(data.userData)); // Save token in localStorage
          localStorage.setItem("ACCESS_TOKEN", JSON.stringify(data.userToken)); // Save token in localStorage
          setLoading(false);
          toast.success("Login Successfully", {
            onClose: () => navigate("/dashboard"), // Navigate after toast is closed
            autoClose: 1000, // Display toast for 3 seconds
          });
          // toast.success("Login Successful");
          // navigate("/dashboard"); // Use navigate instead of history.push
        } else {
          setLoading(false);
          toast.error("Login failed. please check email or password.",{
            autoClose: 1000,
            onClose: (()=>{
              setSubmitting(false);
            })
          });
        }
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        setError("There was an error submitting the form!");
        toast.error("Login failed. please check email or password.");
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Row>
            <Col lg={6} className="loginform">
              <div className="loginmain_div maxwidth500 mx-auto">
                <div className="headlogin_div mb-4">
                  <h2>Welcome to RODEO</h2>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="mb-4 form-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      value={email}
                      placeholder="Enter email address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-4 form-group"
                    style={{ position: "relative", height: "100px" }}
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      required
                      type={showPassword ? "text" : "password"}
                      value={password}
                      placeholder="Enter password"
                      autoComplete="on"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide password.
                    </Form.Control.Feedback>
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </Form.Group>
                  <Form.Group className="mb-4 form-group forgotpassword text-end">
                    <Link to="/ForgotPassword">Forgot Password?</Link>
                  </Form.Group>
                  {/* <Link to="/Dashboard"> */}
                  <Button
                    as="input"
                    type="submit"
                    // onClick={handleSubmit}
                    disabled={submitting}
                    value="Login"
                    className="btn btn-primary w-100"
                  />
                  {/* </Link> */}
                </Form>
              </div>
            </Col>
            <Col lg={6}>
              <div className="loginlogo_div">
                <Link>
                  <img src={logo} alt="" />
                </Link>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </React.Fragment>
      )}
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Sentry } from "react-activity";
export default function GrossProfit() {
  const [positioningSpeed, setPositioningSpeed] = useState("");
  const [GrossProfit, setGrossProfit] = useState("");
  const [OvernightGross, setOvernightGross] = useState("");
  const [FederalTax, setFederalTax] = useState("");
  const [SegmentTax, setSegmentTax] = useState("");
  const [HandlingFee, setHandlingFee] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setSubmitting(true);
      const positioningSpeedobj = {
        positioningSpeed: parseFloat(positioningSpeed),
      };

      try {
        const response = await postApi(
          "insertPositioningSpeed",
          positioningSpeedobj
        );
        if (response?.status) {
          const data = await response.data;
          console.log(data);
          toast.success("Edit Successful", {
            autoClose: 100, // Display toast for 3 seconds
            onClose: () => {
              setSubmitting(false);
            },
          });
        } else {
          setSubmitting(false);
          toast.error(response);
        }
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        setError("There was an error submitting the form!");
      }
    }
  };
  const handleHandlingFeeSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setSubmitting(true);
      const HandlingFeedobj = {
        handleFee: parseFloat(HandlingFee),
      };

      try {
        const response = await postApi("updateHandleFee", HandlingFeedobj);
        if (response?.status) {
          const data = await response.data;
          console.log(data);
          toast.success("Edit Successful", {
            autoClose: 100, // Display toast for 3 seconds
            onClose: () => {
              setSubmitting(false);
            },
          });
        } else {
          setSubmitting(false);
          toast.error(response);
        }
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        setError("There was an error submitting the form!");
      }
    }
  };
  const handleSubmitGrossProfit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setSubmitting(true);
      const grossProfit = {
        grossProfit: parseFloat(GrossProfit),
        overnightGrossProfit: parseFloat(OvernightGross),
      };

      try {
        const response = await postApi("insertProfit", grossProfit);
        if (response?.status) {
          const data = await response.data;
          console.log(data);
          toast.success("Edit Successful", {
            autoClose: 100, // Display toast for 3 seconds
            onClose: () => {
              setSubmitting(false);
            },
          });
        } else {
          setSubmitting(false);
          toast.error(response);
        }
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        setError("There was an error submitting the form!");
      }
    }
  };
  const handleSubmitAddTaxes = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      const tax = {
        federalTax: parseFloat(FederalTax),
        SegmentTax: parseFloat(SegmentTax),
      };

      try {
        const response = await postApi("insertTax", tax);
        if (response?.status) {
          const data = await response.data;
          console.log(data);
          toast.success("Edit Successful", {
            autoClose: 100, // Display toast for 3 seconds
            onClose: () => {
              setSubmitting(false);
            },
          });
        } else {
          setSubmitting(false);
          toast.error(response);
        }
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        setError("There was an error submitting the form!");
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    getApi("getProfit")
      .then((res) => {
        console.log(res.data);
        setGrossProfit(res.data.grossProfit);
        setOvernightGross(res.data.overnightGrossProfit);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });

    getApi("getPositioningSpeed")
      .then((res) => {
        console.log(res.data);
        setPositioningSpeed(res.data.positioningSpeed);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });

    getApi("getTax")
      .then((res) => {
        console.log(res.data);
        setFederalTax(res.data.federalTax);
        setSegmentTax(res.data.SegmentTax);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
    getApi("getHandleFee")
      .then((res) => {
        console.log(res.data);
        setHandlingFee(res.data.handleFee);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Card>
            <Card.Header className="py-4">
              <h5>Positioning Speed</h5>
            </Card.Header>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Positioning Speed</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Positioning Speed"
                        value={positioningSpeed}
                        onChange={(e) => setPositioningSpeed(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide positioning speed.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  className="btn btn-primary px-4 mt-3 min_width140"
                  disabled={submitting}
                >
                  Update
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header className="py-4">
              <h5>Gross Profit</h5>
            </Card.Header>
            <Card.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmitGrossProfit}
              >
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Gross Profit</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Gross Profit"
                        value={GrossProfit}
                        required
                        onChange={(e) => setGrossProfit(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide gross profit.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Overnight Gross Profit</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Overnight Gross Profit"
                        value={OvernightGross}
                        required
                        onChange={(e) => setOvernightGross(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide overnight gross profit.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  className="btn btn-primary px-4 mt-3 min_width140"
                  disabled={submitting}
                >
                  Update
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header className="py-4">
              <h5>Add Taxes</h5>
            </Card.Header>
            <Card.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmitAddTaxes}
              >
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Federal Excise Tax</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Federal Excise Tax"
                        value={FederalTax}
                        required
                        onChange={(e) => setFederalTax(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide federal excise tax.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Segment Tax</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Segment Tax"
                        value={SegmentTax}
                        required
                        onChange={(e) => setSegmentTax(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide segment tax.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  type="submit"
                  disabled={submitting}
                >
                  Update
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header className="py-4">
              <h5>Update Airport Handling Fee</h5>
            </Card.Header>
            <Card.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleHandlingFeeSubmit}
              >
                <Row>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Handling Fee</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Handling Fee"
                        value={HandlingFee}
                        required
                        onChange={(e) => setHandlingFee(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide handling fee.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  type="submit"
                  disabled={submitting}
                >
                  Update
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <ToastContainer />
        </React.Fragment>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getApi, deleteApi } from "./axiosInstance/axiosConfig";
import moment from "moment";
import { Sentry } from "react-activity";
export default function Payments() {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [list, setList] = useState([]);
  useEffect(() => {
    getApi("adminPaymentList", page, perPage, searchText)
      .then((res) => {
        console.log("rsp", res.data.list);
        setList(res.data.list);

        setTotalRows(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, [page, perPage, searchText]);
  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.paymentDetail.transactionId,
      sortable: true,
      cell: (row) => row.paymentDetail.transactionId,
    },
    {
      name: "User Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => row.fullName,
    },
    {
      name: "Amount",
      selector: (row) => row.total,
      sortable: true,
      cell: (row) => `$${row.total.toFixed(2)}`,
    },
    {
      name: "Date",
      selector: (row) => row.bookingDate,
      sortable: true,
      cell: (row) => moment(row.bookingDate).format("YYYY-MM-DD HH:mm"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnview" to={`/Viewpayment/${row._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
        </>
      ),
    },
  ];
  // const data = [
  //     {
  //         id: 1,
  //         transactionid: '#167437898',
  //         username: 'Johnson Charles',
  //         amount: '$2100.00',
  //         date: '26 April 2024',
  //     },
  //     {
  //         id: 2,
  //         transactionid: '#167437899',
  //         username: 'Maria James',
  //         amount: '$878.00',
  //         date: '25 April 2024',
  //     },
  //     {
  //         id: 3,
  //         transactionid: '#167437900',
  //         username: 'David Smith',
  //         amount: '$313.00',
  //         date: '24 April 2024',
  //     },
  //     {
  //         id: 4,
  //         transactionid: '#167437901',
  //         username: 'Steve Jones',
  //         amount: '$99.00',
  //         date: '23 April 2024',
  //     },
  //     {
  //         id: 5,
  //         transactionid: '#167437902',
  //         username: 'James Vince',
  //         amount: '$1099.00',
  //         date: '22 April 2024',
  //     },
  // ]
  // // Filter data based on search text
  // const filteredData = data.filter(
  //     item =>
  //         JSON.stringify(item)
  //             .toLowerCase()
  //             .indexOf(searchText.toLowerCase()) !== -1
  // );

  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Card>
            <Card.Header className="py-4">
              <h5>Payments</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={list}
                progressPending={loading}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </React.Fragment>
      )}
    </>
  );
}
